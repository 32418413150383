<template>
  <div>
    <ul
      v-for="item in ARRAY_LIST_MODEL_SITES"
      :key="item.id"
      class="mb-2 d-flex justify-space-between align-center"
    >
      <li>{{ item.text }}</li>

      <div class="d-flex ml-2">
        <v-btn
          color="primary"
          :href="getUrlOfSite(item.url)"
          target="_blank"
          text
          x-small
        >
          Truy cập
        </v-btn>

        <v-btn
          class="ml-1"
          color="primary"
          text
          x-small
          @click="
            $modal.show({
              name: 'modal-utm-builder',
              payload: getUrlOfSite(item.url)
            })
          "
        >
          UTM Builder
        </v-btn>
      </div>
    </ul>
  </div>
</template>

<script>
import { ARRAY_LIST_MODEL_SITES } from "@/core/constant";

export default {
  props: {
    slug: {
      type: String,
      require: true
    }
  },

  data() {
    return {
      ARRAY_LIST_MODEL_SITES: ARRAY_LIST_MODEL_SITES
    };
  },

  computed: {
    promotion() {
      return this.$store.getters["PROMOTION/promotion"];
    }
  },

  methods: {
    getUrlOfSite(url) {
      return `${url}${this.slug}`;
    }
  }
};
</script>
